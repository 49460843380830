import axios from "axios";

export function uploadEmail(email: string) {
    const config = {
        url: "https://api.ellska.se/public-api/v1/bubbbla/interested",
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            email: email
        },
        timeout: 10000,
    };
    return axios(config);
}


