import React from 'react';
import './Privacy.scss';

function Privacy() {
    return (
        <article className={'Privacy'}>
            <h1>Privacy policy</h1>
            <h3 style={{marginTop: '16px'}}>Personal information</h3>
            <p style={{marginTop: '16px'}}>
                when you register your interest with Bubbbla we will store your email address in order to be
                able to contact you closer to the product launch. We will not share your email with any thirdparty
                companies.
            </p>
            <p style={{marginTop: '16px'}}>
                Contact us at <a
                style={{textDecoration: "underline"}}
                href={'mailto:info@bubbbla.com'}>info@bubbbla.com</a> if you want your email address removed.
            </p>
        </article>
    );
}

export default Privacy;
