import React, {useState} from 'react';
import './Home.scss';
import {Link} from "react-router-dom";
import classNames from "classnames";
import Button from "../buttons/Button";
import Loader from "../loader/Loader";
import {uploadEmail} from "../network/emailApi";

const checkboxKey = "check"

function Home() {
    const [policiesChecked, setPoliciesChecked] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);

    const handleCheckboxChanged = () => {
        setPoliciesChecked(!policiesChecked);
    };

    const handleEmailChanged = (event: React.FormEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
    };

    const onSubmitForm = () => {
        if (!validateEmail(email)) {
            setError('Invalid email');
            return;
        }
        setError(undefined);
        setLoading(true);
        uploadEmail(email).then(() => {
            setComplete(true);
        }).catch((error) => {
            setError("Failed to submit email, try again.");
            setLoading(false);
        })

    }

    const formFilledIn = policiesChecked && email !== '' && !loading;

    return (
        <article className={'Home'}>
            <div className={'textContainer'} style={{paddingTop: '32px', paddingBottom: '32px'}}>
                <div className={classNames('container')}>
                    <h1>Kids communication.<br/>Safe, fun & digital.
                    </h1>
                    <p className={'bodyText'}>The Bubbbla app is the safe & secure stepping stone for kids into the
                        world of
                        digital communication,
                        with age appropriate functions, UX and app design.<br/>
                        <br/>
                        Children communicate within their ‘bubbbla’ with family and friends, tailored to the parents
                        security
                        and safety preferences with contact administration.<br/>
                        <br/>
                        Bubbbla is aiming to be the safest, most fun, easiest and most accessible digital communication
                        tool
                        for
                        children between the age of 5 to 12 and their parents.
                    </p>
                </div>
            </div>
            <form style={{marginTop: '24px'}} className={classNames('container', 'registerForm')}>
                <h2>Register your email to be the first to know when we launch:
                </h2>
                {complete &&
                  <h1 style={{marginTop: '24px', color: "#9dd4cb", fontWeight: 900}}>Thank you for your interest,<br/>we
                    will be intouch!
                  </h1>}
                {!complete && <>
                  <input value={email} style={{marginTop: '24px'}} placeholder={'Enter your email here'}
                         className={classNames('emailInput')} type="email"
                         onChange={handleEmailChanged}/>
                    {error && <p style={{marginTop: '8px'}} className={'errorMessage'}>{error}</p>}
                  <div className={'bottomFormRow'}>
                    <div className={'checkbox-wrapper'}>
                      <label htmlFor={checkboxKey}>
                        <input className={classNames('checkboxInput', policiesChecked && 'checked')} type="checkbox"
                               checked={policiesChecked}
                               onChange={handleCheckboxChanged}
                               id={checkboxKey}/>
                        <p>I have read and agree to the <Link className={'privacyLink'} target={'_blank'}
                                                              to={'privacy'}>privacy policy</Link>.</p>
                      </label>
                    </div>
                    <Button className={'submitButton'} onClick={onSubmitForm} disabled={!formFilledIn}>{loading ?
                        <Loader/> : 'SEND'}</Button>
                  </div>
                </>}
            </form>
        </article>
    );
}

const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export default Home;
