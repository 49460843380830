import React from 'react';
import './Button.scss';
import classNames from "classnames";

function Button({onClick, children, disabled, className}: ButtonProps) {
    return (
        <button disabled={disabled} className={classNames('button', className)} onClick={onClick}>
            {children}
        </button>
    );
}

interface ButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    children: any;
    className?: string;
}

export default Button;
